@import url('https://fonts.googleapis.com/css?family=Noto+Nastaliq+Urdu');
.Polaris-DataTable__Cell {
  padding: 1rem;
}
.Polaris-Link {
  text-decoration: none;
}

/* @media (min-width: 48.0625em) {
  .Polaris-Frame--hasTopBar_1162r .Polaris-Frame__Navigation_1ajsq {
    height: calc(100% - 5.6rem);
  }
} */

/* #content {
  box-sizing: border-box;
  height: auto;
  margin: auto auto;
  overflow: hidden;
  padding: 0.5in;
  width: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  background: linen;
  border-radius: 1px;
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
}

#content .Polaris-Heading {
  width: 100%;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fe6b01;
  color: white;
  text-transform: uppercase;
}
#content .Polaris-Layout {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  padding: 0;
  text-align: center;
}
#content > .Polaris-Layout__Section > img {
  width: 200px;
  margin-top: 15px;
  margin-bottom: 15px;
} */
/* #content .span {
  text-align: center;
}
#content .details {
  display: block;
  width: 100%;
  float: left;
}
#content .Polaris-Layout__Section {
  margin: 0;
}
#content .Polaris-TopBar {
  background: #fe6b01;
}
#content .Polaris-Frame__Content {
  background: #fde6d6;
}
#content .Polaris-Navigation__Item:hover {
  background: #fe6b01;
}
#content .Polaris-ActionList {
  background: white;
  color: black;
}
#content .Polaris-ActionList__Item:hover {
  color: white;
  background-color: #fe6b01;
}
#content .Polaris-Icon {
  display: inline-block;
}
#content .Polaris-Card {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 0;
} */

/* #content .foot-address {
  margin-top: 25px;
  text-align: center;
  font-weight: bold;
} */

/* New Template */

/* @media only screen and (min-width: 769px) {
  .background-div {
    width: 90%;
  }
} */

.background-div {
  width: 70%;
}

.login-page {
  max-width: 40%;
  margin: 0 auto;
}

.login-page .logo-image img {
  width: 184px;
}

.vibrantTextContainer {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}

.vibrantContainer {
  display: grid;
  gap: 5px;
  justify-items: start;
  position: relative;
  border: 2px solid #016938;
  padding: 10px 10px;
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 0 0 0 #01693880; */
  animation: border 0.5s linear infinite alternate-reverse both
    /* ,pulse 1s linear infinite */;
}

.vibrantText {
  color: #282828;
  font-size: 16px;
  font-weight: 600;
}

@keyframes border {
  to {
    border-color: transparent;
  }
}
@keyframes pulse {
  100% {
    box-shadow: 0 0 0 20px #0000;
  }
}

@media only screen and (max-width: 768px) {
  .login-page {
    max-width: 70%;
  }
}

@media only screen and (max-width: 567px) {
  .login-page {
    max-width: 100%;
  }
}
.Rating {
  display: flex;
}

.Rating__Star {
  color: transparent;
  fill: #eec200;
}

.rating .Polaris-Icon--colorSuccess svg {
  color: #eec200;
  fill: #eec200;
}

.rating .Polaris-Icon {
  height: 0.7rem;
}

.rating-badge .Polaris-Badge {
  padding: 0rem 0.2rem;
  background-color: transparent;
}

.rating {
  width: 10px;
}

.survey-remarks {
  font-size: 12px;
}

/* Ingredient */

#ingredient-ur .Polaris-Labelled__LabelWrapper {
  direction: rtl;
}

/* Short URL - Social Icons */

.imgFluid {
  max-width: 100%;
  height: auto;
}
.iconCard {
  display: flex;
  gap: 20px;
}
.iconCardImg {
  width: 150px;
  aspect-ratio: 1;
  min-width: 150px;
  height: fit-content;
  box-shadow: 0 0 5px -3px black;
  border-radius: 5px;
}
.iconCardTxtDesc {
  margin-bottom: 5px;
}
.social-icons ul {
  list-style-type: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-block: 20px;
}
.social-icons ul li {
  background-color: #ffffff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 5px -3px black;
  position: relative;
}
.social-icons ul li {
  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 5px -3px black;
  overflow: hidden;
  position: relative;
  background-position: center;
  transition: background 0.8s;
  z-index: 1;
}
.socialBtn {
  text-decoration: none;
  color: #282828;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.social-icons ul li:hover {
  background: #ffffff radial-gradient(circle, transparent 1%, #ffffff 1%)
    center/15000%;
}
.social-icons ul li:active {
  background-color: #28282812;
  background-size: 100%;
  transition: background 0s;
}
.social-icons ul li:hover {
  cursor: pointer;
}
.social-icons ul li svg {
  width: 30px;
  max-height: 25px;
  margin-right: 5px;
}
#youtube .socialBtn {
  color: #282828;
  display: flex;
  justify-content: center;
  align-items: center;
}
#tiktok .socialBtn {
  color: #282828;
}
#instagram .socialBtn {
  color: #282828;
}

/* Assessment Test */

#counterMain {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #282828;
}
.descTxtHead {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 15px;
  letter-spacing: 3px;
  text-align: center;
  line-height: 1.2;
  text-transform: uppercase;
}
.descTxt {
  font-weight: bold;
  font-size: 55px;
  text-align: center;
  letter-spacing: 10px;
  text-transform: uppercase;
  line-height: 1.2;
}
@media (max-width: 768px) {
  .descTxtHead {
    font-size: 14px;
    font-size: 4vw;
  }
  .descTxt {
    font-size: 20px;
    font-size: 6.3vw;
  }
}
