* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-family: 'Outfit', sans-serif;
}
:root {
  --main-app-color: #ffffff;
  --body-color: #575768;
  --btn-bg: #eeeeee;
  --login-btn: #212529;
  --login-btn-active: #1c1f23;
  --btn-bg-active: #e1e1e1;
  --radius-ui: 10px;
  --secondary-bs: #eeeeee;
  --main-shadow: 0px 1px 3px lightgrey;
  --dark-bs: #212529;
  --success-bs: #198754;
  --light-bs: #f8f9fa;
}

main {
  background-color: var(--main-app-color);
}
.chat-messages {
  /* overflow-x: scroll; */
  max-height: 500px;
  min-height: 500px;
  padding-right: 5px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: var(--main-app-color);
  scrollbar-color: var(--secondary-bs) var(--main-app-color);
  scrollbar-width: thin;
}
.chat-messages::-webkit-scrollbar {
  width: 7px;
  background-color: var(--main-app-color);
}
.chat-messages::-webkit-scrollbar-thumb {
  background-color: var(--secondary-bs);
  border-radius: 20px;
}
#main-app {
  /* max-width: 425px;
  min-width: 320px; */
  /* max-height: 425px;
  min-height: 425px;
  overflow-y: scroll; */
  background-color: var(--main-app-color);
  height: 100%;
  color: var(--dark-bs);
  margin-inline: auto;
  /* border-radius: 20px; */
}
/* mainScreen Start */
#header {
  position: sticky;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  background-color: var(--main-app-color);
}
.header-profile {
  position: absolute !important;
  top: 0rem;
  left: 0rem;
  max-width: 425px;
  min-width: 320px;
  margin: auto;
  right: 0rem;
  background-color: var(--main-app-color);
}
.header-title {
  padding-bottom: 1rem;
  padding-inline: 1.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.backbtnThreads {
  color: var(--dark-bs);
}
#header > .header-title {
  font-weight: 500;
}

.searchBar {
  height: 40px;
  color: var(var(--dark-bs));
  padding-inline: 15px;
  padding-block: 5px;

  border-radius: 50px;
  background-color: var(--btn-bg);
}
.newMsgBtn {
  width: 40px;
  border: 0;
  height: 40px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: var(--dark-bs);
  background-color: var(--btn-bg);
  cursor: pointer;
  margin-left: 0.5rem;
  border-radius: 50px;
}
.newMsgBtn:active {
  background-color: var(--btn-bg-active) !important;
}
.searchOuter {
  /* opacity: 0;
  visibility: hidden; */
  transition: all 0.4s ease;
  display: none;
  margin-bottom: 10px;
}
.newMsgOuter {
  /* opacity: 0;
  visibility: hidden; */
  transition: all 0.4s ease;
  display: flex;
  margin-inline: 1.5rem;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.MsgScreenSeparator {
  margin-inline: 1.5rem;
  color: var(--secondary-bs);
}
.searchBar {
  width: 100%;
  border: 0;
}
.searchBar:focus {
  outline: none;
}

#header > .header-title > .search,
#header > .header-title > .btns-messages > .search,
#header > .header-title > .btns-messages > div > .logout,
#header > .header-title > div > .logout {
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  color: var(--dark-bs);
  background-color: var(--btn-bg);
  cursor: pointer;
}
#header > .header-title > .search:active,
#header > .header-title > .btns-messages > .search:active,
#header > .header-title > .btns-messages > div > .logout:active {
  background-color: var(--btn-bg-active) !important;
}
#header > .header-title > .search > i::before {
  font-weight: bold !important;
}
#chats-section .chats-box {
  margin-bottom: 20px;
}
.msg-time-outer {
  display: flex;
  justify-content: flex-end;
}
.chats-section {
  padding-inline: 1.5rem;
}
#chats-section .chats-box {
  border-radius: var(--radius-ui);
  /* box-shadow: var(--main-shadow); */
  background-color: var(--btn-bg);
  cursor: pointer;
}
.profile-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profilePage-inner {
  background-color: var(--main-app-color);
}
/* a span:after {
    content: '';
    width: 0%;
    margin: auto;
    display: block;
    height: 2px;
    transition: all 0.4s ease;
    background-color: var(--dark-bs);
  }
  a span:active:after {
    width: 47%;
  } */
#chats-section {
  background-color: var(--main-app-color);
  color: var(--dark-bs);
}
.chats-message .chats-box:active {
  background-color: var(--btn-bg-active) !important;
}
.chat-icon {
  margin: auto;
}
.chat-icon-outer {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  overflow: hidden;
  background-color: var(--secondary-bs);
}
.chat-icon-inner {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 50px;
  overflow: hidden;
}
.chat-text {
  flex: 1;
}
.status-inner {
  height: 7px;
  width: 7px;
}
.status-time {
  font-size: small;
}
/* MainScreen End */
/* ChatScreen Start */
#header > .header-title > div > .search {
  width: 40px;
  height: 40px;
  display: flex;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  background-color: var(--btn-bg);
  cursor: pointer;
}
#header > .header-title > div > .search:active {
  background-color: var(--btn-bg-active) !important;
}
#header > .header-title > div > .search > i::before {
  font-weight: bold !important;
}
footer#inputmsg {
  position: sticky;
  bottom: 0rem;
  left: 0rem;
  padding-inline: 1.5rem;
  right: 0rem;
  background-color: var(--main-app-color);
}
footer#inputmsg .inputmsg-inner {
  padding-bottom: 20px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
}
footer#inputmsg input {
  flex: 1;
}
footer#inputmsg input:focus {
  outline: none;
}
.msg-field {
  background-color: var(--light-bs);
  color: var(--dark-bs);
  border-radius: 50px;
  border: 0 !important;
  padding-inline: 1rem;
  padding-block: 0.5rem;
}
.send-btn {
  width: 50px;
  margin-left: 0.5rem;
  border-radius: 50px;
  height: 50px;
  font-size: larger !important;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: var(--light-bs);
}
.sendBtnLoading {
  border: 2px solid;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 1.5s linear infinite reverse;
}
.send-btn i::before {
  font-weight: bold !important;
  transform: rotate(45deg) translate(-1px, 2px);
}
.send-btn:active {
  background-color: var(--btn-bg-active) !important;
}
/* messages */
.msg-box {
  background-color: var(--btn-bg);
  padding: 10px;
  max-width: 80%;
  margin-bottom: 20px;
  border-radius: var(--radius-ui);
  cursor: pointer;
}
.msg-box:active {
  opacity: 0.6;
}
.chatboxes-msgs {
  color: var(--dark-bs) !important;
}
.text-body-ui {
  color: var(--dark-bs) !important;
}
.msg-time {
  font-size: 12px;
  margin-top: 0.5rem;
}
.msg-outgoing {
  display: flex;
  justify-content: flex-end;
}
.msg-outgoing .msg-box {
  box-shadow: var(--main-shadow);
}
.msg-incoming {
  display: flex;
  justify-content: flex-start;
}
.form-control-ui {
  height: 60px;
  padding-top: 25px !important;
  padding-bottom: 10px !important;
  background-color: var(--btn-bg) !important;
  color: var(--dark-bs) !important;
  border-radius: var(--radius-ui) !important;
}
.form-control-ui:focus,
.btnLogin:focus {
  box-shadow: none !important;
}
.label-ui {
  position: absolute;
  bottom: 25px;
  left: 12px;
  font-size: 14px;
}
.btnLogin {
  height: 60px;
  border-radius: var(--radius-ui) !important;
  background-color: var(--login-btn) !important;
}
.btnLogin:hover {
  background-color: var(--login-btn-active) !important;
}
.mainAppLogin {
  height: 100vh;
}
.main-app-loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-ui {
  width: 3rem !important;
  height: 3rem !important;
  border-left-color: transparent !important;
}
.profileImg {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.profileImg img {
  background-color: var(--btn-bg);
  width: 150px;
  padding: 10px;
  border-radius: 100px;
}
.change-profile-txt {
  color: var(--dark-bs);
}
.msg-refresh i::before {
  animation: rotate 2s linear infinite reverse;
}
@keyframes rotate {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
/* ChatScreen End */
